<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>AIOT</el-breadcrumb-item>
            <el-breadcrumb-item>酒店管理</el-breadcrumb-item>
            <el-breadcrumb-item>房型管理</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 表头 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>名称/编码：</label>
                  <el-input class="width-220" v-model="condition" :placeholder="$t('msg.room_name_code')"  clearable></el-input>
               </el-row>
               <el-button class="bg-gradient" type="primary" @click="handleQuery(true)" ><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button type="primary" @click="handleQuery(false)"  plain><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 表格 -->
         <div class="table-box">
            <div class="table-head-btn">
               <el-row></el-row>
               <el-row class="right-btn">
                  <el-button class="bg-gradient" type="primary" @click="handleAdd" ><span v-text="$t('msg.add_room_type')">新建房型</span></el-button>
               </el-row>
            </div>
            <el-table v-loading="loading"
                     :data="tableData"
                     border fit
                     highlight-current-row
                     style="width: 100%"
                     :stripe="true"
                     >
               <el-table-column :label="$t('msg.number')" width="70">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="hotel_log" :label="$t('msg.room_icon')" width="70">
                  <template slot-scope="scope">
                     <img v-if="scope.row.icon" :src="scope.row.icon" width="40" height="40" class="v-align-middle"/>
                     <img v-else src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" width="40" height="40" class="v-align-middle" alt="">
                  </template>
               </el-table-column>
               <el-table-column prop="roomTypeName" :label="$t('msg.roomtype_name')" min-width="110"></el-table-column>
               <el-table-column prop="roomTypeCode" :label="$t('msg.roomtype_code')" min-width="110"></el-table-column>
               <el-table-column prop="createTime" :label="$t('msg.add_time')" min-width="150" sortable></el-table-column>
               <el-table-column prop="remark" :label="$t('msg.remark')" min-width="180"></el-table-column>
               <el-table-column :label="$t('msg.operating')" :min-width="operWidth" fixed="right">
                  <template slot-scope="scope">
                     <el-button  type="text" icon="el-icon-edit" @click="handleEdit(scope.row)" >
                     <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                     </el-button>
                     <el-button  type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="handleDel(scope.row.id)" >
                     <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="currChange"/>
         </div>
         <!-- 添加/编辑房型 -->
         <el-dialog :title="action === 'add' ? '新建房型' : '编辑房型'" :visible.sync="isShow" width="550px" :before-close="handleClose">
            <el-form class="form-dialog-box">
               <el-form-item style="width:100%" label="图标">
                  <template>
                     <el-radio v-model="radioIcon" label="1"><span v-text="$t('msg.font_icon')">字体图标</span></el-radio>
                     <el-radio v-model="radioIcon" label="2"><span v-text="$t('msg.upload_icon')">上传图标</span></el-radio>
                  </template>
               </el-form-item>
               <el-form-item>
                  <el-input  class="width-300" v-if="radioIcon === '1'" :placeholder="$t('msg.font_icon')" clearable></el-input>
                  <div v-else class="cate-upload">
                     <el-upload
                           class="upload-demo"
                           :limit="1"
                           drag
                           accept="image/png"
                           action="#"
                           :auto-upload="false"
                           :on-change="handleChange">
                        <p style="float: left;line-height: 31px">上传图标</p>
                        <i class="el-icon-upload"></i>
                     </el-upload>
                     <div class="old-icon" v-if="oldIconShow">
                        <p><i class="el-icon-document"></i>{{oldIcon}}</p>
                        <span @click="oldIconShow = false" class="el-icon-close"></span>
                     </div>
                  </div>
               </el-form-item>
               <el-form-item required label="房型名称">
                  <el-input  class="width-300" v-model="roomTypeName" :placeholder="$t('msg.roomtype_name')" clearable></el-input>
               </el-form-item>
               <el-form-item required label="房型编码">
                  <el-input  class="width-300" v-model="roomTypeCode" :placeholder="$t('msg.roomtype_code')" clearable></el-input>
               </el-form-item>
               <el-form-item label="备注信息">
                  <el-input class="width-300 type-textarea" type="textarea" v-model="remark" :placeholder="$t('msg.inp_remark_info')"></el-input>
               </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
               <el-button  @click="handleReset" v-text="$t('msg.reset')">重置</el-button>
               <el-button class="bg-gradient" type="primary" @click="handleSave" v-text="$t('msg.save')">保存</el-button>
            </span>
         </el-dialog>
      </el-row>
  </div>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import { pms } from '@/api/interface/pms'
   import common from '@/common/js/common'
   export default {
      data () {
         return {
            tableData: [],        // 表格数据
            total: 0,             // 数据总数
            loading: true,        // 加载动画效果
            isShow: false,        // 是否显示弹窗
            action: '',           // 当前操作行为
            radioIcon: '1',       // 图标按钮
            oldIcon: '',          // 旧图标
            oldIconShow: false,   // 旧图标隐藏/显示
            operWidth: 0,         // 编辑栏宽度
            /* 查询列表字段 */
            limit: 1,             // 每页显示数
            page: 1,              // 当前页
            condition: '',        // 房型名称/编码
            /* 表单提交字段 */
            id: '',               // 房型ID
            file: '',             // 图片资源
            roomTypeName: '',     // 房型名称
            roomTypeCode: '',     // 房型编码
            remark: '',           // 备注
            /* 提示文本 */
            confirm: '确定',
            cancel: '取消',
            prompt: '提示！',
            add_success: '添加成功！',
            update_success: '修改成功！',
            confirm_remove: '确定移除？',
            remove_success: '移除成功！',
            room_no_null: '房型号不能为空！',
            own_hotel_no_null: '所属酒店不能为空！',
            room_floor_no_null: '房型楼层不能为空！',
            room_type_no_null: '房型不能为空！',
            star_room_no_null: '开始房型号不能为空！',
            end_room_no_null: '结束房型号不能为空！',
            room_type_name_no_null: '房型名称不能为空！',
            room_type_code_no_null: '房型编码不能为空！',
         }
      },
      mounted () {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.getRoomTypes()
      },
      methods: {
         // 获取房型列表
         getRoomTypes(){
            const url = urlObj.getRoomTypePages
            const param = {
               limit: this.limit,
               page: this.page,
               condition: this.condition
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.loading = false
                  this.total = res.total
                  let tableData = res.records
                  tableData.forEach(item => {
                     item.icon = item.icon && window.getResourcesHost() + item.icon
                  })
                  this.tableData = tableData
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 改变每页数
         pageChange(num) {
            this.limit = num
            this.getRoomTypes()
         },
         // 改变当前页
         currChange(num) {
            this.page = num
            this.getRoomTypes()
         },
         // 查询房型
         handleQuery(bool) {
            if (bool) return this.getRoomTypes()
            this.condition = ''
         },
         // 获取上传文件资源
         handleChange(file){
            this.file = file.raw
         },
         // 添加房型
         handleAdd(){
            this.isShow = true
            this.action = 'add'
         },
         // 编辑房型
         handleEdit(row){
            this.isShow = true
            this.action = 'edit'
            this.id = row.id
            // 表单回显
            this.roomTypeName = row.roomTypeName
            this.roomTypeCode = row.roomTypeCode
            this.remark = row.remark
            if (row.icon.substr(-3) === 'png'){
               this.radioIcon = '2'
               this.oldIconShow = true
               const arr = row.icon.split('/')
               this.oldIcon = arr[arr.length-1]
            }
         },
         // 重置表单
         handleReset(){
            this.file = null
            this.oldIcon = ''
            this.oldIconShow = false
            this.radioIcon = '1'
            this.roomTypeName = ''
            this.roomTypeCode = ''
            this.remark = ''
         },
         // 保存房型
         handleSave(){
            // 表单验证
            if (!this.roomTypeName) {
               this.$alert(this.room_type_name_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.roomTypeCode) {
               this.$alert(this.room_type_code_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let url = urlObj.addRoomType
            let param = new FormData()
            if (this.file){
               param.append('file', this.file)             // 文件
               param.append('module', 'hotel')       // 所在模块
               param.append('menu', 'roomtype')      // 所属菜单
               param.append('func', 'icon')          // 图片功能
               param.append('category', 'icon')      // 图片分类
               param.append('isThumb', true)         // 是否开启缩略图
            }
            param.append('roomTypeName', this.roomTypeName)
            param.append('roomTypeCode', this.roomTypeCode)
            param.append('remark', this.remark)
            if (this.action === 'edit') {
               url = pms.editRoomType
               param.append('id', this.id)
            }
            this.$axios.post(url, param, 'file').then((res) => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.add_success,
                     type: 'success'
                  })
                  this.addRoom = false
                  this.getRoomTypes()
                  this.handleClose()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 关闭弹窗
         handleClose() {
            this.isShow = false
            this.handleReset()
         },
         // 删除房型
         handleDel(id){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               let url = urlObj.deleteRoomType + `/${id}`
               this.$axios.get(url, {}).then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.action === 'add' ? this.remove_success : this.update_success,
                        type: 'success'
                     })
                     this.getRoomTypes()
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            })
         },
      }
   }
</script>
<style lang="scss">
   .cate-upload{
      position: relative;
   }
   .cate-upload .upload-demo{
      height: 32px;
   }
   .cate-upload .el-upload-dragger{
      width: 300px;
      height: 32px;
      text-align: right;
      border: solid 1px #DCDFE6;
      padding: 0 5px 0 16px;
      font-size: 13px;
      border-radius: 4px;
      color: #cccccc;
   }
   .cate-upload .el-upload-dragger .el-icon-upload{
      line-height: 29px;
      margin: 0;
      font-size: 16px;
   }
   .cate-upload .old-icon{
      top: 0;
      position: absolute;
      width: 260px;
      display: flex;
      justify-content: space-between;
      border-radius: 4px;
      padding: 0 4px;
      background: #f5f7fa;
      box-sizing: border-box;
   }
   .cate-upload .old-icon p{
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      line-height: 32px;
      text-overflow:ellipsis;
   }
   .cate-upload .old-icon p i{
      margin: 0 4px 0 0;
   }
   .cate-upload .old-icon span{
      line-height: 32px;
   }
   .cate-upload .el-upload-list{
      position: absolute;
      top: 0;
      width: 260px;
      background: #f5f7fa;
   }
   .cate-upload .el-upload-list li{
      margin-top: 0!important;
      line-height: 32px;
   }
   .cate-upload .el-upload-list__item .el-icon-close{
      top: 9px;
   }
</style>
